import BasePage from '../../../../../../components/table_page';
import Form from '../form/form.vue';

import { ProcessLog } from '../../../../../../modules';
import request from '../../../../../../utils/request';

export default {
  name: 'activity_manage',
  extends: BasePage,
  data() {
    return {
      searchFormData: {
        // approveStatus: 'create',
      },
      params: {
        ext3: 'project',
      },

    };
  },
  components: {
    Form,
    ProcessLog,
  },
  async created() {
    await this.getConfigList('tpm_audit_act_req_vo');
    // this.formData.approveStatus = 'create';
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 审批通过 approved
      // 审批中 approving
      // 活动关闭 closed
      // 流程追回 interrupt
      // 驳回 rejected
      const { approveStatus, processCode } = row;
      if (
        approveStatus
        && code === 'edit'
        && (approveStatus === 'interrupt'
          || approveStatus === 'closed'
          || approveStatus === 'approving'
          || approveStatus === 'approved')
      ) {
        return false;
      }
      if (
        approveStatus
        && code === 'submit_approval'
        && (approveStatus === 'closed'
          || approveStatus === 'approving'
          || approveStatus === 'approved')
      ) {
        return false;
      }
      if (code === 'process_log' && !processCode) {
        return false;
      }

      return true;
    },
    // 按钮前置
    // beforeButtonClick({ val }) {
    //   let list = [];
    //   let state = true;
    //   if (val.code === 'delete') {
    //     if (this.selectRow.length === 0) {
    //       this.$message('请选择一条数据');
    //       return false;
    //     }

    //     list = this.selectRow.map((v) => v.approveStatus);

    //     state = list.every((v) => v === 'create');
    //     if (!state) {
    //       this.$message.error('只有待提交的数据允许删除操作');
    //       return false;
    //     }
    //   } else if (val.code === 'activities_close') {
    //     list = this.selectRow.map((v) => v.approveStatus);

    //     state = list.every((v) => v === 'approved');
    //     if (!state) {
    //       this.$message.error('只有审批通过的数据才能关闭');
    //       return false;
    //     }
    //   }
    //   return true;
    // },
    modalClick({ val, row }) {
      const { code, name } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          code,
          row,
        };
        this.modalConfig.title = name || '查看';
        this.openFull();
      } else if (code === 'submit_approval') {
        request
          .get('/tpm/tpmAuditController/query', {
            id: row.id,
          })
          .then((res) => {
            if (res.success) {
              const rowData = { ...res.result };
              rowData.saveType = '5';
              this.onSubmit({ row: rowData, submitUrl: '/tpm/tpmAuditController/approve' });
            }
          });
      } else if (code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';
        this.modalConfig.width = '50%';

        this.formName = 'ProcessLog';
        this.openDrawer();
      }
    },
    // 保存回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
        tips: ['核销提示：', '1、以EXCEL格式形式录入信息', '2、核销资料：描述、示例、实际上传内容'],
      };

      this.$emit('approve', configParams);
    },
  },
};
